// api.js
import http from '@/utils/httpRequest';


class Api {
  // 示例：登录接口
  login(username, password) {
    return http.post('/login', {
      username,
      password,
    });
  }

  // 示例：获取用户信息接口
  getUserInfo(userId) {
    return http.get(`/users/${userId}`);
  }


  getDeviceList(name) {
    return http.post(`/system/api/device/list`,{
      name: name,
    });
  }

  getStateInfoList(value){
    return http.post(`/system/api/device/list`,{
      isOnline: value,
    });
  }

  deletespeech(value){
    return http.delete(`/system/api/device/speech/delete`,{
      params: { // 对于DELETE请求，通常不用于发送请求体，但如果API设计为接收查询参数，可以这样使用
        deviceCode: value
      }
    });
  }

  getspeechList(value,text,type){
    return http.post(`/system/api/device/speech`,{
      deviceCode: value,
      text: text,
      type: type
    });
  }

  updateVolumeValue(value,volume){
    return http.post(`/system/api/device/setVolume`,{
      deviceCode: value,
      volume: volume,
    });
  }

  // 可以继续添加更多的API方法
}

export default new Api();
