import "core-js/modules/es.array.push.js";
import api from '@/utils/Api';
import { showNotify, closeNotify, showDialog, showConfirmDialog } from 'vant';
export default {
  name: 'MainEquipment',
  data() {
    return {
      isStart: false,
      isSelect: false,
      state: 1,
      content: '',
      //播放内容
      deviceData: '',
      //搜索框内容
      deviceNum: '',
      //设备编码
      deviceValue: 0,
      //设备列表下拉框绑定值
      stateValue: 0,
      //设备状态下拉框绑定值
      onlineDevice: 0,
      //在线设备数量
      offlineDevice: 0,
      //离线设备数量
      deviceName: '设备名称',
      //设备名称
      tenantIdValue: '',
      accessTokenValue: '',
      volume: 45,
      //音量
      stateList: [{
        text: '全部状态',
        value: 0
      }, {
        text: '在线',
        value: 1
      }, {
        text: '离线',
        value: 2
      }],
      deviceList: [],
      deviceInfoList: [],
      deviceCode: '',
      text: '',
      type: ''
    };
  },
  created() {},
  // eslint-disable-next-line vue/no-dupe-keys
  props: ['deviceCode1', 'text1', 'type1'],
  mounted() {
    this.isSelect = false;
    this.tenantIdValue = this.$route.query.id;
    this.accessTokenValue = this.$route.query.token;

    //获取数据
    this.getDataInfo();
    // 保存数据到缓存
    const isDeviceCodeEmpty = this.$route.query.deviceCode1;
    if (isDeviceCodeEmpty === undefined) {
      this.deviceCode = "";
    } else {
      this.deviceCode = this.$route.query.deviceCode1;
    }
    const isTextEmpty = this.$route.query.text1;
    if (isTextEmpty === undefined) {
      this.text = "";
    } else {
      this.text = this.$route.query.text1;
      this.isSelect = true;
    }
    const isTypeEmpty = this.$route.query.type1;
    if (isTypeEmpty === undefined) {
      this.type = "";
    } else {
      this.type = this.$route.query.type1;
    }
  },
  methods: {
    //跳转选择页面,value为设备编号
    goPlay(value) {
      //跳转文本页面
      this.$router.push({
        path: '/PlaySelection',
        query: {
          deviceCode: value
        }
      });
    },
    //刷新
    refreshData() {
      this.isSelect = false;
      this.getDataInfo();
      this.deviceData = '';
    },
    //调用查询接口
    async searchInfo() {
      try {
        const response = await api.getDeviceList(this.deviceData);
        if (response.code == '0') {
          if (response.data != null) {
            this.deviceInfoList = [];
            this.deviceInfoList = response.data;
            this.deviceValue = 0;
            this.stateValue = 0;
          }
        } else {
          showNotify({
            type: 'danger',
            message: response.msg
          });
        }
      } catch (error) {
        console.error('查询数据异常', error);
      }
    },
    //获取数据
    async getDataInfo() {
      try {
        const response = await api.getDeviceList('');
        if (response.code == '0') {
          if (response.data != null) {
            this.deviceInfoList = response.data;
            if (this.deviceCode != "") {
              if (this.isSelect) {
                // 遍历 deviceInfoList，找到匹配的项并设置 isSelect
                this.deviceInfoList.forEach(item => {
                  if (item.deviceCode === this.deviceCode) {
                    item.isSelect = true;
                  }
                });
              }
            }
            const namesList = response.data.map(item => ({
              text: item.name,
              value: item.id
            }));
            namesList.push({
              text: '所有设备',
              value: 0
            });
            this.deviceList = namesList;
            this.deviceValue = 0;
            this.stateValue = 0;

            //统计所有在线/离线设备
            const statList = response.data.map(item => item.isOnline);
            let countTrue = 0;
            let countFalse = 0;
            statList.forEach(item => {
              if (item) {
                countTrue++;
              } else {
                countFalse++;
              }
            });
            this.onlineDevice = countTrue;
            this.offlineDevice = countFalse;
          }
        } else {
          showNotify({
            type: 'danger',
            message: response.msg
          });
        }
      } catch (error) {
        console.error('获取数据异常', error);
      }
    },
    //点击状态下拉菜单事件
    async getStateInfoList() {
      // eslint-disable-next-line no-unused-vars
      let state;
      if (this.stateValue === 1) {
        //在线
        state = true;
        this.deviceValue = 0;
      } else if (this.stateValue === 2) {
        //离线
        state = false;
        this.deviceValue = 0;
      }
      try {
        const response = await api.getStateInfoList(state);
        if (response.code == '0') {
          if (response.data != null) {
            this.deviceInfoList = [];
            this.deviceInfoList = response.data;
          }
        } else {
          showNotify({
            type: 'danger',
            message: response.msg
          });
        }
      } catch (error) {
        console.error('调用接口异常', error);
      }
    },
    //点击设备下拉菜单事件
    async getDataInfoList() {
      this.stateValue = 0;
      const item = this.deviceList.find(item => item.value === this.deviceValue);
      // eslint-disable-next-line no-unused-vars
      let name = item.text;
      if (this.deviceValue === 0) {
        name = '';
      }

      //调用接口，name为所点击的value值
      try {
        const response = await api.getDeviceList(name);
        if (response.code == '0') {
          if (response.data != null) {
            this.deviceInfoList = [];
            this.deviceInfoList = response.data;
          }
        } else {
          showNotify({
            type: 'danger',
            message: response.msg
          });
        }
      } catch (error) {
        console.error('调用接口异常', error);
      }
    },
    //停止播放
    async deletespeech(item, value) {
      try {
        const response = await api.deletespeech(value);
        if (response.code == '0') {
          if (response.data.code == '200' && response.data.message == 'OK') {
            this.state = 1;
            this.isStart = false;
            item.isStart = false;
            item.contents = "";
            showNotify({
              type: 'success',
              message: "停止成功"
            });
            // this.getDataInfo();
          }
        } else {
          showNotify({
            type: 'danger',
            message: response.msg
          });
        }
      } catch (error) {
        console.error('停止失败', error);
      }
    },
    //调用播放接口
    async getspeech(item, value, online) {
      if (!online) {
        return showNotify({
          type: 'warning',
          message: "请设备已离线"
        });
      }
      if (this.text === "" && !this.isStart) {
        return showNotify({
          type: 'warning',
          message: "请选择播放内容"
        });
      }
      if (this.deviceCode === value && this.state === 1) {
        try {
          const response = await api.getspeechList(value, this.text, this.type);
          if (response.code == '0' && response.data != null) {
            this.state = 0;
            this.getDataInfo();
            this.isStart = true;
            item.isStart = true;
            this.isSelect = false;
            item.isSelect = false;
            this.text = '';
            showNotify({
              type: 'success',
              message: "播放成功"
            });
          } else {
            showNotify({
              type: 'danger',
              message: response.msg
            });
          }
        } catch (error) {
          console.error('播放失败', error);
        }
      } else {
        this.deletespeech(item, value);
      }
    },
    async updateVolume(value, volume) {
      try {
        const response = await api.updateVolumeValue(value, volume);
        if (response.code === 0 && response.data.code === 200) {
          showNotify({
            type: 'success',
            message: "修改音量成功"
          });
          this.getDataInfo();
        } else {
          showNotify({
            type: 'danger',
            message: response.msg
          });
        }
      } catch (error) {
        console.error('音量修改失败', error);
      }
    }
  }
};