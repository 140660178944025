import axios from 'axios';
import { showDialog ,} from 'vant';
// 创建一个axios实例
const http = axios.create({
  baseURL: 'https://xy.guodun.org.cn/tenant-api', // 你的API基础URL
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
http.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 例如，添加Authorization头部
    const token = localStorage.getItem('token');
    const tenantId = localStorage.getItem('tenantId');
    const userId = localStorage.getItem('userId');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (tenantId) {
      config.headers.tenantId = `${tenantId}`;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    if(response.data && response.data.code === 0){
      return response.data;
    }else {
      response = response.data;
      let code = response.code;
      if (response && code === 401) {
        // 处理401错误，比如重定向到登录页面
        window.location.href = "/Login";
      }else if (code === 500) {
        showDialog({
          title: '错误',
          message: response.msg,
        }).then(() => {
          window.location.href = "/Login";
        });
        return Promise.reject(new Error(response.msg))
      }
    }

  },
  error => {
    // 对响应错误做点什么
    if (error.response && error.response.status === 401) {
      // 处理401错误，比如重定向到登录页面
      window.location.href = "/Login";
    }
    return Promise.reject(error);
  }
);

export default http;
